<template>
    <div>
        <g-loading v-if="firstLoading" />
        <div v-else class="card title">
            <div class='card-header c-leader-head text-left'>
                <h3 class='card-title' style="float: none">
                    Chỉ số báo cáo theo năm <i class='text-danger float-right'>{{Object.keys(indicators).length}} chỉ số</i>
                </h3>
            </div>
            <div class="card-body title-filter">
                <div class="row">
                    <div class="col-4">
                        <div class="btn-group btn-group-sm ml-1 mb-1">
                            <a :class="[view === 'detail' ? 'active' : '']"
                               class="btn btn-outline-gray btn-sm is-border-1 js-sales-pipeline-expand"
                               @click="view = 'detail'">
                                Chi tiết
                            </a>
                            <a :class="[view === 'report' ? 'active' : '']"
                               class="btn btn-outline-gray btn-sm is-border-1 js-sales-pipeline-expand"
                               @click="view = 'report'">
                                Trực quan
                            </a>
                        </div>
                    </div>
                    <div class="col-8 title-filte-right">
                        <b-tabs class="c-tabs-button mr-2" small pills>
                            <b-tab v-for="year in params.years" :key="year"  :title="`Năm ${year}`" :active="filterFormModel.year == year" @click='setTab(year)'></b-tab>
                        </b-tabs>
                        <g-button class="ml-2" v-if="permissions('indicator-manual_create')" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                        <button  class="btn btn-gray btn-sm mr-1 ml-1" @click="reset">
                            <b-spinner v-if="listLoading" small></b-spinner>
                            <i v-else class="fa fa-sync-alt" ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body title-body pt-0">
                <div v-if="view === 'detail'" class='title-template-table table-responsive'>
                    <table class='table b-table table table-hover table-head-fixed table-striped table-hover b-table-selectable b-table-select-single'>
                        <thead>
                            <tr>
                                <th class='text-center'>Kì báo cáo</th>
                                <th class='text-center'>Cập nhật lúc</th>
                                <th class='text-right' >Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="!items.length">
                            <tr><td colspan='3' class='text-center text-danger'>Không tìm thấy thông tin</td></tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="item in items" :key="item.id" >
                                <td class='text-center'>{{item.year}}</td>
                                <td class='text-center'>{{ item.updated_time * 1000 | datetimeFormat }}</td>
                                <td class='text-right'>
                        <g-button v-if="permissions('indicator-manual_update')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil" @click="update(item.id)" />
                        <g-button  v-if="permissions('indicator-manual_del')" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                        </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="c-leader-table">
                    <table v-if="items.length" class='table table-striped table-hover table-bordered'>
                        <tr>
                            <th class='text-center' style="min-width: 200px;" >Chỉ số</th>
                            <th v-for="(label, province_id) in params.provinces" :key="province_id" class='text-center'>{{label}}</th>
                        </tr>
                        <tbody>
                            <tr v-for="(indicator, code) in indicators" :key="code" >
                                <td><b>{{code}}.</b> {{indicator.name}}</td>
                        <template v-for="(label, province_id) in params.provinces" >
                            <td :key="'1th-' + province_id"  class='text-center'>{{getVal(-1, province_id, code)}}</td>
                        </template>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal v-model="showForm" size="xl" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <form-input :disabled='formModel.id' type='number' label="Năm" :model="formModel" attribute="year" :errors="formErrors"/>
            <table v-if="formModel.indicators" class='table table-striped table-hover table-bordered'>
                <tr>
                    <th rowspan="2" class='text-center'>Chỉ số</th>
                    <th :colspan="Object.keys(params.provinces).length" class='text-center'>Giá trị</th>
                </tr>
                <tr>
                    <th v-for="(label, province_id) in params.provinces" :key="province_id" class='text-center'>
                        {{label}}
                    </th>
                </tr>
                <tbody>
                    <tr v-for="(indicator, code) in indicators" :key="code" >
                        <td><b>{{code}}.</b> {{indicator.name}}</td>
                        <td v-for="(label, province_id) in params.provinces" :key="province_id">
                <form-input type='number' :model="formModel.indicators[code]" :attribute="province_id" :errors="formErrors" class='mb-0'/>
                </td>
                </tr>
                </tbody>
            </table>

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import moment from 'moment';

    export default {
        components: {},
        mixins: [list, crud, role],
        data: function () {
            return {
                autoLoad: false,
                service: '/indicator-manual',
                defaultFilterFormModel: {
                    year: moment().format("YYYY"),
                    quarter: [-1],
                    indicators: [],
                },
                defaultFormModel: {
                    quarter: -1,
                    year: moment().format("YYYY"),
                    indicators: null,
                },
                view: 'detail',
                indicators: {}
            };
        },
        methods: {
            getModel(quarter, year) {
                let key = `q${quarter}/${year}`;
                return this.params.items[key] ? this.params.items[key] : null;
            },
            getVal(quarter, province_id, indicator) {
                let val = '';
                let item = this.getModel(quarter, this.filterFormModel.year);
                if (item && item.items[province_id] && item.items[province_id][indicator]) {
                    return item.items[province_id][indicator];
                }
                return val;
            },
            setTab(tab) {
                this.filterFormModel.year = tab;
                this.load();
            },
            beforeUpdate(data) {
                if(data.indicators.length <= 0 ){
                  data.indicators = {};
                }
                for (let indicator in this.defaultFormModel.indicators) {
                    if (!data.indicators[indicator]) {
                        data.indicators[indicator] = {};
                    }
                    for (let province_id in this.params.provinces) {
                        if (!data.indicators[indicator][province_id]) {
                            data.indicators[indicator][province_id] = null;
                        }
                    }
                }
            },
            beforeLoad() {
                this.indicators = {};
                this.defaultFormModel.indicators = {};
                for (let code in this.$params.indicator) {
                    let indicator = this.$params.indicator[code];
                    if (!indicator.manual || !indicator.report.includes("year")) {
                        continue;
                    }
                    this.indicators[code] = indicator;
                    let obj = {};
                    for (let province_id in this.params.provinces) {
                        obj[province_id] = null;
                    }
                    this.defaultFormModel.indicators[code] = obj;

                }
            }

        },
        mounted: function () {
            let indicators = [];
            for (let code in this.$params.indicator) {
                let indicator = this.$params.indicator[code];
                if (!indicator.manual || !indicator.report.includes("year")) {
                    continue;
                }
                indicators.push(code);
            }
            this.defaultFilterFormModel.indicators = indicators;
            this.filterFormModel.indicators = indicators;
            this.load();
        }
    }
</script>