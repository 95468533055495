<template>
    <div class="row">
        <div class="col-12" >
            <form-quarter />
            <form-year />
            <form-turn />
        </div>
    </div>
</template>

<script>
    import FormQuarter from './FormQuarter';
    import FormYear from './FormYear';
    import FormTurn from './FormTurn';

    export default {
        components: {FormQuarter, FormYear, FormTurn},
    }
</script>